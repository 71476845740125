import { KeyValuePair } from 'tailwindcss/types/config'
import { assets } from '@/helpers/common'

const goToPageTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
}

/*
const scrollToDiv = (id: string) => {
  if (typeof window !== 'undefined') {
    const element = document.getElementById(id)

    window.scrollTo({
      top: element?.getBoundingClientRect().top,
      behavior: 'smooth'
    })

    /!*element?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })*!/
  }
}

*/

type ScrollToDivParams = {
  id: string
  increaseOffset?: number
  decreaseOffset?: number
}
const scrollToDiv = ({ id, increaseOffset, decreaseOffset }: ScrollToDivParams) => {
  const element = document.getElementById(id)

  if (element) {
    const baseOffset = element.getBoundingClientRect().top + window.scrollY

    const offsetTop = baseOffset + (increaseOffset ?? 0) - (decreaseOffset ?? 0)

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth'
    })
  }
}

const scrollToTop = () => {
  if (typeof window !== 'undefined' && window.innerWidth < 768) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const getLocation = (location_info: KeyValuePair) => {
  const location = []

  const city = location_info?.city || ''
  const zip_code = location_info?.zipcode || ''

  if (city) location.push(city)
  if (zip_code) location.push(zip_code)

  return location.join(' - ')
}

type AddressProps = {
  city?: string
  state?: KeyValuePair
  zip_code?: string
  country?: KeyValuePair
}

function formatAddress({ city, state, zip_code, country }: AddressProps): string {
  const address = []
  const stateName = state?.s_code ? state.s_code : state?.state || state?.state_name || ''

  if (city) address.push(city)
  if (stateName) address.push(stateName)
  if (zip_code) address.push(zip_code)
  if (country?.iso2) address.push(country.iso2.toUpperCase())

  return address.join(', ')
}

export const Helpers = {
  getLocation,
  formatAddress,
  goToPageTop,
  scrollToDiv,
  scrollToTop
}

export default Helpers

export const boxTypeIcon: Record<string, string> = {
  luggage: assets('images/box-type-icon-luggage.png'),
  box: assets('images/box-type-icon-box.png'),
  boxes: assets('images/box-type-icon-box.png'),
  golf_box: assets('images/box-type-icon-golf-bag.png'),
  'golf-bag': assets('images/box-type-icon-golf-bag.png'),
  golfbag: assets('images/box-type-icon-golf-bag.png'),
  skis: assets('images/box-type-icon-skis.png')
}
