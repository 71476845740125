import { cn } from '@/lib/utils'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { m } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

const InfoPopOver = function (props) {
  const title = useRef(null)

  const [popoverVisible, setPopoverVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', onCloseHandle)

    return () => {
      window.removeEventListener('resize', onCloseHandle)
    }
  }, [])

  const getPopoverContent = useCallback(() => {
    return (
      <m.div
        className={cn('popover-hover-content', props?.contentClassName)}
        style={{ display: popoverVisible ? 'block' : 'none' }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        mode={'wait'}
      >
        <div className='arrow'></div>
        {props.children}
      </m.div>
    )
  }, [popoverVisible, props.children])

  const initPopoverElement = useCallback(() => {
    let popoverRoot = document.getElementById('popover-root')

    if (!popoverRoot) {
      popoverRoot = document.createElement('div')
      popoverRoot.setAttribute('id', 'popover-root')
      document.body.append(popoverRoot)
    }

    return popoverRoot
  }, [])

  const getOffsetPosition = useCallback(element => {
    let tempElement = element.current,
      offsetLeft = 0,
      offsetTop = 0

    while (tempElement.offsetParent !== null) {
      offsetLeft += tempElement.offsetLeft
      offsetTop += tempElement.offsetTop

      tempElement = tempElement.offsetParent
    }

    return { offsetLeft, offsetTop }
  }, [])

  const setPopoverPosition = useCallback(
    popoverRoot => {
      let titleEl = title.current
      let popoverContentEl = getPopoverContent()

      // eslint-disable-next-line react/no-deprecated
      ReactDOM.render(popoverContentEl, popoverRoot)

      let popoverElement = popoverRoot.querySelector('.popover-hover-content')
      let popoverArrow = popoverRoot.querySelector('.popover-hover-content > .arrow')

      let titleOffset = getOffsetPosition(title)

      /*let viewportOffset = title.current.getBoundingClientRect()
            titleOffset = {
              offsetLeft: viewportOffset.x,
              offsetTop: viewportOffset.y
            }*/

      Object.assign(popoverElement.style, {
        display: 'block'
      })

      let top = titleOffset.offsetTop + titleEl.offsetHeight + 10
      let left = 0
      let right = 'auto'
      let arrowLeft = 0

      let popoverConOW = popoverElement.clientWidth,
        leftOffsetWidth = titleOffset.offsetLeft + popoverConOW / 2

      // start calculate left position
      if (window.innerWidth < leftOffsetWidth) {
        left = 'auto'
        right = window.innerWidth - (titleOffset.offsetLeft + titleEl.clientWidth)
      } else if (titleOffset.offsetLeft < popoverConOW / 2) {
        left = 30
      } else {
        left = titleOffset.offsetLeft - popoverElement.clientWidth / 2
      }
      // end calculate left position

      if (top && left) {
        Object.assign(popoverElement.style, {
          display: 'block',
          position: 'absolute',
          left: left === 'string' ? left : `${left}px`,
          right: right === 'string' ? right : `${right}px`,
          top: `${top}px`
        })

        // arrow position setting
        arrowLeft =
          titleOffset.offsetLeft - popoverElement.offsetLeft - popoverArrow.clientWidth / 2 + titleEl.clientWidth / 2
        Object.assign(popoverArrow.style, {
          left: `${arrowLeft}px`
        })
      }

      // console.dir(popoverElement.clientHeight);
      if (typeof window !== 'undefined' && window.innerWidth < 768) {
        // window.scrollTo({ top: titleOffset.offsetTop - 10, behavior: 'smooth' })
      }
    },
    [getPopoverContent, getOffsetPosition]
  )

  const onOpenHandle = useCallback(
    event => {
      event.preventDefault()
      let popoverRoot = initPopoverElement()
      setPopoverPosition(popoverRoot)
      setPopoverVisible(true)
    },
    [initPopoverElement, setPopoverPosition]
  )

  const onCloseHandle = useCallback(
    event => {
      event.preventDefault()
      let popoverRoot = initPopoverElement()

      // eslint-disable-next-line react/no-deprecated
      ReactDOM.unmountComponentAtNode(popoverRoot)
      setPopoverVisible(false)
    },
    [initPopoverElement]
  )

  let popoverEvents = {}
  if (typeof window !== 'undefined' && window.innerWidth < 768) {
    popoverEvents = {
      onClick: onOpenHandle,
      onMouseLeave: onCloseHandle
    }
  } else {
    popoverEvents = {
      onClick: onOpenHandle,
      onMouseEnter: onOpenHandle,
      onMouseLeave: onCloseHandle
    }
  }

  return (
    <>
      <span className={cn(`info-icon text-black relative`, props.className)} ref={title} {...popoverEvents}>
        {props.icon ? <img src={props.icon} alt='' /> : <FontAwesomeIcon icon={faQuestion} width={20} height={10} />}
      </span>
    </>
  )
}

export default InfoPopOver
