interface LocalStorageHook<T> {
  getLocal: (key: string) => T | null
  setLocal: (key: string, value: T) => void
  removeLocal: (key: string) => void
  removeLocals: (keys: string[]) => void
}

function useLocalStorage<T>(): LocalStorageHook<T> {
  const getLocal = (key: string): T | null => {
    const item = localStorage.getItem(key)

    return item ? JSON.parse(item) : null
  }

  const setLocal = (key: string, value: T): void => localStorage.setItem(key, JSON.stringify(value))

  const removeLocal = (key: string): void => localStorage.removeItem(key)

  const removeLocals = (keys: string[]): void => keys.forEach(key => localStorage.removeItem(key))

  return {
    getLocal,
    setLocal,
    removeLocal,
    removeLocals
  }
}

export default useLocalStorage
